import { DropDownNew } from "components/DropDown/DropDownNew";
import styles from "./HeaderMenuActionsDropDown.module.scss";
import HeaderMenuActions from "./HeaderMenuActions";
import { useEffect, useRef } from "react";
import { useHeaderDispatch } from "./Provider/HeaderContext";
import { ThreeDots } from "@armtekfrance/armtek-react-design-system/dist/assets";

export default function HeaderMenuActionsDropDown() {
  const divRef = useRef<HTMLDivElement>(null);
  const dispatchHeader = useHeaderDispatch();

  useEffect(() => {
    if (divRef.current !== null) {
      dispatchHeader({
        type: "setLeftMenuActions",
        setLeftMenuActions: divRef.current.getBoundingClientRect().left - 200,
      });
    }

    const handleResize = () => {
      if (divRef.current !== null) {
        dispatchHeader({
          type: "setLeftMenuActions",
          setLeftMenuActions: divRef.current.getBoundingClientRect().left - 200,
        });
      }
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div ref={divRef} className={styles.headerMenuActionsDropDownWrapper}>
      <DropDownNew
        placement="bottomRight"
        dropdownContent={<HeaderMenuActions />}
      >
        <div className={styles.threeDotsButton}>
          <ThreeDots />
        </div>
      </DropDownNew>
    </div>
  );
}
