import styles from "./DocumentsModelsHeroCreateWrapper.module.scss";
import {
  Button,
  Dialog,
  Input,
  InputType,
  SizeType,
} from "@armtekfrance/armtek-react-design-system";

import { useTranslation } from "react-i18next";
import { useCallback, useState } from "react";
import {
  ApiDocumentFamilyEnum,
  ApiDocumentSpecification,
  ApiDocumentUpsert,
  ApiLanguageEnum,
} from "@armtekfrance/corenexus-pn-sn";
import { SlChangeEvent } from "@shoelace-style/shoelace";
import { Manager } from "@felixkletti/managementjs";
import {
  DocumentModelCloudActions,
  GlobalStore,
  useIsOngoing,
} from "@armtekfrance/webgate/dist";
import { tap } from "rxjs";
import { useNavigate } from "react-router-dom";
import DropDownList, {
  DropDownListItem,
} from "components/DropDown/DropDownList";
import { DropDownNew } from "components/DropDown/DropDownNew";

// TODO : Reuse this for KNOWLEDGE PAGE and HUMAN
export default function DocumentModelsSystemCreateWrapper() {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const [openDialog, setOpenDialog] = useState(false);
  const [modelName, setModelName] = useState("");
  const [selectedType, setSelectedType] = useState("");

  const isOnGoing = useIsOngoing(
    DocumentModelCloudActions.DOCUMENT_MODEL_TYPES
  );

  const getSystemTypes = (): DropDownListItem[] => {
    const listItems: DropDownListItem[] = [];
    const systemTypes: Array<ApiDocumentSpecification> | undefined =
      GlobalStore.modelEditor?.documentTypes?.find(
        (item) => item.family === ApiDocumentFamilyEnum.SYSTEM
      )?.types;

    if (systemTypes && systemTypes?.length > 0) {
      systemTypes.forEach((item) => {
        listItems.push({
          key: item.type,
          label: interpretLabel(i18n.language as ApiLanguageEnum, item.label),
        });
      });
    }
    return listItems;
  };

  function interpretLabel(
    language: ApiLanguageEnum,
    label: { fr: string; en: string }
  ): string {
    let message: string | null = "";

    switch (language) {
      case ApiLanguageEnum.fr:
        message = label.fr;
        break;
      case ApiLanguageEnum.en:
      default:
        message = label.en;
        break;
    }

    return message;
  }

  const closeDialog = () => {
    setOpenDialog(false);
  };

  const onClickCreate = () => {
    const modelUpsert: ApiDocumentUpsert = {
      name: modelName || "",
      type: selectedType,
      // TODO : Change this to use context from future props
      family: ApiDocumentFamilyEnum.SYSTEM,
      auto_image_set: false,
      subtitle: null,
      about: null,
      language: i18n.language as ApiLanguageEnum,
    };
    Manager.API.emit(DocumentModelCloudActions.DOCUMENT_MODEL_UPSERT, {
      model: modelUpsert,
    })
      .pipe(
        tap((data) => {
          if (data?.response?.models?.length > 0) {
            const modelCreatedId = data.response.models[0].id;
            if (modelCreatedId) {
              navigateToSystemModelEditor(modelCreatedId);
            }
          }
        })
      )
      .subscribe();
  };

  const onChangeName = (event: SlChangeEvent) => {
    const value = (event.target as HTMLInputElement).value;
    setModelName(value);
  };

  const navigateToSystemModelEditor = (modelId: number) => {
    navigate(`/account/system/models/edit/${modelId}`);
  };

  const onClickSelectType = useCallback(
    (item: DropDownListItem) => {
      setOpenDialog(true);
      setSelectedType(item.key);
    },
    [selectedType, setSelectedType]
  );

  return (
    <div
      className={styles.documentsModelsHeroCreateWrapperWrapper}
      data-cy="DocumentModelsSystemCreateWrapper"
    >
      {!isOnGoing && (
        <DropDownNew
          placement="bottomRight"
          dropdownContent={
            <DropDownList
              dataCy={"SystemTypesDropDownList"}
              listItems={getSystemTypes()}
              onClickItem={(item) => onClickSelectType(item)}
            />
          }
        >
          <Button
            child={t("doc.models.create")}
            dataCy="Button-CreateSystemModels-ModelsList"
          />
        </DropDownNew>
      )}
      <Dialog
        title={t("doc.models.createTitleDialog")}
        onHide={closeDialog}
        size={SizeType.small}
        child={
          <Input
            value={modelName}
            type={InputType.text}
            onChange={onChangeName}
            placeholder={t("doc.models.modelNameInputPlaceholder")}
            dataCy="Dialog-CreateNamingSystemModels-ModelsList"
          />
        }
        open={openDialog}
        footer={
          <div className={styles.rowGap}>
            <Button
              child={t("button.cancel")}
              onClick={closeDialog}
              dataCy="Button-CancelCreateModelDialog-ModelsList"
            />
            <Button
              child={"OK"}
              onClick={onClickCreate}
              dataCy="Button-OkCreateModelDialog-ModelsList"
            />
          </div>
        }
        footerStyle={{ display: "flex", justifyContent: "right" }}
      ></Dialog>
    </div>
  );
}
