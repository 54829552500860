import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import MediaPickerModal from "components/MediaPicker/MediaPickerModal";
import { ApiDocumentFamilyEnum } from "@armtekfrance/corenexus-pn-sn";
import { MediaAction, ModelBuilder } from "@armtekfrance/webgate/dist";
import { navigation } from "Router/NavigationService";
import { useTranslation } from "react-i18next";
import { Manager } from "@felixkletti/managementjs";
import styles from "./KnowledgeEditorPage.module.scss";
import { useSearchParams } from "react-router-dom";
import { fetchDocumentCategories } from "app/stores/documentCategories/documentCategoriesClient";
import { ModelHeaderInfoButton } from "./ModelHeaderInfoButton";

export default function ModelEditPage() {
  const { id } = useParams();

  const [searchParams] = useSearchParams();
  const sectionIdParam = searchParams.get("sectionid");
  // const { sectionId } = useParams();
  const { t } = useTranslation();

  const [documentModelMediaId, setDocumentModelMediaId] = useState<
    number | undefined
  >(undefined);

  interface ItemProps {
    children: React.ReactNode;
    value: string;
    [key: string]: any;
  }

  interface Category {
    active: boolean;
    category_name: string;
    company_id: number;
    created_at: string;
    id: number;
    parent_id: number | null;
    updated_at: string;
    _count: {
      document_model: number;
      sub_categories: number;
    };
  }

  useEffect(() => {
    if (id) {
      fetchDocumentCategories({ active: true }, 1, 1000).then((response) => {
        setCategoriesList(
          response.data.docCategories.map((category: Category) => ({
            children: category.category_name,
            value: category.id.toString(),
          }))
        );
      });
    }
    // eslint-disable-next-line
  }, [id]);

  const [categoriesList, setCategoriesList] = useState<ItemProps[]>([]);
  const [subCategoriesList, setSubCategoriesList] = useState<ItemProps[]>([]);
  const [selectedCategory] = useState("");
  const [selectedSubCategory] = useState("");

  const fetchSubCategories = async (categoryId: string) => {
    const response = await fetchDocumentCategories(
      { parent_id: parseInt(categoryId), active: true },
      1,
      1000
    );
    const subCategoriesList: ItemProps[] = response.data.docCategories.map(
      (category: Category) => ({
        children: category.category_name,
        value: category.id.toString(),
      })
    );
    setSubCategoriesList(subCategoriesList);
    return subCategoriesList;
  };

  useEffect(() => {
    const observers: Array<() => void> = [];
    // Here we need to use custom emit so we can check if it is for cover photo
    Manager.on(MediaAction.MEDIA_SELECTION_CONFIRMED, (data, done) => {
      setDocumentModelMediaId(data?.mediaContents[0]?.id);

      observers.push(done);
    });

    return () => observers.forEach((unsubscribe) => unsubscribe());
  }, []);

  return (
    <>
      {id ? (
        <>
          <MediaPickerModal />
          <ModelBuilder
            modelId={parseInt(id!)}
            mediaId={documentModelMediaId}
            documentFamily={ApiDocumentFamilyEnum.KNOWLEDGE}
            onDelete={() => {
              navigation.to("account/knowledge/models/list");
              return;
            }}
            section_id={sectionIdParam ? parseInt(sectionIdParam) : undefined}
            docCategories={categoriesList}
            subCategories={subCategoriesList}
            selectedCategory={selectedCategory}
            selectedSubCategory={selectedSubCategory}
            fetchSubCategories={fetchSubCategories}
            additionalFirstLineButtons={[<ModelHeaderInfoButton id={id!} />]}
          />
        </>
      ) : (
        <div className={styles.centerContainer}>
          <h2>{t("systemModelRetrieveError")}</h2>
        </div>
      )}
    </>
  );
}
