import DocumentModelsHeroCreateWrapper from "./DocumentsModelsHeroCreateWrapper";
import { documentTypeMenuItems } from "../documentsModelsTypesDropDown/EnumTypesContent";
import DocumentModelsSystemCreateWrapper from "./DocumentModelsSystemCreateWrapper";
import { ApiDocumentFamilyEnum } from "@armtekfrance/corenexus-pn-sn";
// import { DocumentTypeConst } from "app/stores/documents/types";

export default function DocumentModelsHeroCreate({
  documentFamily,
}: {
  documentFamily: ApiDocumentFamilyEnum;
}) {
  if (documentFamily === ApiDocumentFamilyEnum.KNOWLEDGE) {
    return (
      <DocumentModelsHeroCreateWrapper typeMenuItems={documentTypeMenuItems} />
    );
  }

  if (documentFamily === ApiDocumentFamilyEnum.SYSTEM) {
    return <DocumentModelsSystemCreateWrapper />;
  }

  return <p>Error</p>;
}
