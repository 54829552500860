import { Layout, LayoutType } from "@armtekfrance/webgate/dist";
import DocumentsModelsBody from "./DocumentsModelsBody";
import { ApiDocumentFamilyEnum } from "@armtekfrance/corenexus-pn-sn";
import DocumentsModelsFilters from "./DocumentsModelsFilters";

const DocumentsModelsPage = ({
  documentFamily,
}: {
  documentFamily: ApiDocumentFamilyEnum;
}) => {
  return (
    <Layout
      layoutType={LayoutType.NO_SIDE}
      header={<DocumentsModelsFilters documentFamily={documentFamily} />}
      content={<DocumentsModelsBody />}
    />
  );
};

export default DocumentsModelsPage;
