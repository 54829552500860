import { useTranslation } from "react-i18next";
import { Alert, Button, Form, Input } from "antd";
import { AiOutlineLock, AiOutlineUser } from "react-icons/ai";
import { Link } from "react-router-dom";
import {
  AuthCloudActions,
  AuthStatus,
  GlobalStore,
  useStoreState,
  useIsOngoing,
  webGate,
} from "@armtekfrance/webgate/dist";
import { useEffect, useState } from "react";
import { navigation } from "../../Router/NavigationService";
// import { useIsOngoing } from "libs/useIsOngoing";
import { Manager } from "@felixkletti/managementjs";
import { ResponseIQL } from "iql-core";
import { ApiLanguageEnum } from "@armtekfrance/corenexus-pn-sn";

export default function Login() {
  const { i18n, t } = useTranslation();

  const loginOngoing = useIsOngoing(AuthCloudActions.AUTH_LOGIN);

  const [status] = useStoreState<AuthStatus>(GlobalStore.auth.status);

  useEffect(() => {
    if (status?.authenticated) {
      navigation.toDefault();
    }
  }, [status]);
  const onSubmit = async (values: { email: string; password: string }) => {
    webGate.authManager.login(values.email, values.password);
  };

  function interpreterIqlMsg(
    language: ApiLanguageEnum,
    iq: ResponseIQL<unknown, unknown>
  ): string | null {
    let message: string | null = "";

    switch (language) {
      case ApiLanguageEnum.fr:
        message = iq?.translations?.messages?.fr || null;
        break;
      case ApiLanguageEnum.en:
        message = iq?.translations?.messages?.en || null;
        break;
      default:
    }

    if (!message) {
      message = iq?.message || null;
    }

    if (!message) {
      message = iq?.error?.data?.message || null;
    }

    if (!message) {
      message = null;
    }

    return message;
  }

  const [errorMsg, setErrorMsg] = useState<string | null>(null);
  const [authFailed, setAuthFailed] = useState<boolean>(false);
  Manager.API.on(AuthCloudActions.AUTH_LOGIN).subscribe((next) => {
    setErrorMsg(interpreterIqlMsg(i18n.language as ApiLanguageEnum, next));

    if (!next.success) {
      setAuthFailed(true);
    }
  });

  return (
    <div>
      {authFailed === true && (
        <Alert
          message={errorMsg}
          type="error"
          className="mb-3"
          showIcon
          closable
        />
      )}
      <Form
        name="normal_login"
        className="login-form"
        initialValues={{ remember: true }}
        onFinish={onSubmit}
        data-cy="Login_Form"
      >
        <Form.Item
          name="email"
          rules={[
            {
              required: true,
              type: "email",
              message: `${t("loginPage.input")} ${t("loginPage.email")}`,
            },
          ]}
        >
          <Input
            prefix={<AiOutlineUser className="site-form-item-icon" />}
            placeholder={t("loginPage.email")}
            size="large"
            data-cy="Login_Input_Email"
          />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[
            {
              required: true,
              message: `${t("loginPage.input")} ${t("loginPage.password")}`,
            },
          ]}
        >
          <Input.Password
            prefix={<AiOutlineLock className="site-form-item-icon" />}
            type="password"
            placeholder={t("loginPage.password")}
            size="large"
            data-cy="Login_Input_Password"
          />
        </Form.Item>

        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            className="login-form-button"
            size="large"
            loading={loginOngoing}
            block
            data-cy="Login_Button_Submit"
          >
            {t("loginPage.login")}
          </Button>
        </Form.Item>
      </Form>
      <div>
        <Link
          style={{
            display: "absolute",
            position: "fixed",
            bottom: "0",
            margin: "0",
            left: "0.5rem",
            fontSize: "8px",
            color: "#8c8c8c",
            opacity: ".7",
          }}
          to={"/buildInfo"}
        >
          {/* @ts-ignore */}
          build:{window!.ARMTEK_PROD_VERSION}
        </Link>
      </div>
    </div>
  );
}
