import styles from "./MediaPickerMediasGrid.module.scss";
import React, { useEffect, useState } from "react";
import MediaPickerModalImageWrapper from "./MediaPickerModalImageWrapper";
import {
  FetchNextPageOptions,
  InfiniteData,
  InfiniteQueryObserverResult,
} from "@tanstack/react-query";
import { MediaContent } from "Stores/media-models";
import { PaginationType } from "app/stores/pagination/types";
import MediaPickerLoadMoreFooter from "./MediaPickerLoadMoreFooter";
import MediaPickerModalVideoWrapper from "./MediaPickerModalVideoWrapper";
import MediaPickerModalAudioWrapper from "./MediaPickerModalAudioWrapper";
import MediaPickerModalFileWrapper from "./MediaPickerModalFileWrapper";
import { GlobalStore, useStoreState } from "@armtekfrance/webgate/dist";

export default function MediaPickerMediasGrid({
  mediaContents,
  onFetchNextPage,
  hasNextPage,
  isFetching,
  isFetchingNextPage,
}: {
  mediaContents:
    | InfiniteData<{
        mediaContents: MediaContent[];
        pagination: PaginationType;
      }>
    | undefined;
  onFetchNextPage: (options?: FetchNextPageOptions | undefined) => Promise<
    InfiniteQueryObserverResult<
      {
        mediaContents: MediaContent[];
        pagination: PaginationType;
      },
      unknown
    >
  >;
  hasNextPage: boolean | undefined;
  isFetching: boolean;
  isFetchingNextPage: boolean;
}) {
  useStoreState(GlobalStore.media.getSelectedMedias());

  const [scrollRemaining, setScrollRemaining] = useState<number>(0);

  const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
    setScrollRemaining(
      event.currentTarget.scrollHeight -
        event.currentTarget.scrollTop -
        event.currentTarget.clientHeight
    );
  };

  useEffect(() => {
    onFetchNextPage();
  }, []);

  useEffect(() => {
    if (
      scrollRemaining < 200 &&
      hasNextPage !== false &&
      isFetching === false
    ) {
      onFetchNextPage();
    }
  }, [scrollRemaining]);

  return (
    <div
      className={styles.mediaPickerMediasGridWrapper}
      onScroll={handleScroll}
    >
      {mediaContents?.pages.map((page, indexGroup) => (
        <React.Fragment key={indexGroup}>
          {page.mediaContents.map((mediaContent, indexMediaContent) => (
            <React.Fragment key={indexMediaContent}>
              {mediaContent.type === "image" && (
                <MediaPickerModalImageWrapper
                  imageItem={mediaContent}
                  convertToBase64
                  enablePreview={true}
                  checked={GlobalStore.media.isMediaSelectedById(
                    mediaContent.id
                  )}
                  onCheckedChange={(checked) => {
                    if (checked === false) {
                      GlobalStore.media.removeFromSelectedMediaById(
                        mediaContent.id
                      );
                    }
                    if (
                      GlobalStore.media.getMediaPickerModalStatus()
                        .isCoverImageSelection
                    ) {
                      GlobalStore.media.setSelectedMedias([]);
                    }
                    if (checked === true) {
                      GlobalStore.media.pushToSelectedMedia(mediaContent);
                    }
                  }}
                />
              )}
              {mediaContent.type === "video" && (
                <MediaPickerModalVideoWrapper
                  videoItem={mediaContent}
                  convertToBase64
                  checked={GlobalStore.media.isMediaSelectedById(
                    mediaContent.id
                  )}
                  onCheckedChange={(checked) => {
                    if (checked === true) {
                      GlobalStore.media.pushToSelectedMedia(mediaContent);
                    }
                    if (checked === false) {
                      GlobalStore.media.removeFromSelectedMediaById(
                        mediaContent.id
                      );
                    }
                  }}
                />
              )}
              {mediaContent.type === "audio" && (
                <MediaPickerModalAudioWrapper
                  audioItem={mediaContent}
                  checked={GlobalStore.media.isMediaSelectedById(
                    mediaContent.id
                  )}
                  onCheckedChange={(checked) => {
                    if (checked === true) {
                      GlobalStore.media.pushToSelectedMedia(mediaContent);
                    }
                    if (checked === false) {
                      GlobalStore.media.removeFromSelectedMediaById(
                        mediaContent.id
                      );
                    }
                  }}
                />
              )}
              {mediaContent.type === "file" && (
                <MediaPickerModalFileWrapper
                  fileItem={mediaContent}
                  checked={GlobalStore.media.isMediaSelectedById(
                    mediaContent.id
                  )}
                  onCheckedChange={(checked) => {
                    if (checked === true) {
                      GlobalStore.media.pushToSelectedMedia(mediaContent);
                    }
                    if (checked === false) {
                      GlobalStore.media.removeFromSelectedMediaById(
                        mediaContent.id
                      );
                    }
                  }}
                />
              )}
            </React.Fragment>
          ))}
        </React.Fragment>
      ))}
      <MediaPickerLoadMoreFooter
        hasNextPage={hasNextPage}
        isFetching={isFetching}
        isFetchingNextPage={isFetchingNextPage}
      ></MediaPickerLoadMoreFooter>
    </div>
  );
}
